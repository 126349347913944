<template>
    <div :key="pageKey">
        <appic-page-title-bar ref="breadcrumbBar" :documentTitle="updateMode ? Epr__title : ''" help-page-component="AddEpr"></appic-page-title-bar>
        <app-section-loader :status="loader"/>
        <v-container fluid grid-list-xl pb-0 pt-3 px-3>
            <v-layout row wrap>
                <EprAlert
                        :alert="$t('message.closeAlert')"
                        type="warning"
                        class="mt-3"
                        mode="add"
                        v-if="addMode && newExportPurchaseRequestUnsaved"
                />
                <EprAlert
                        :alert="$t('message.closeAlert')"
                        type="warning"
                        class="mt-3"
                        mode="update"
                        v-if="updateMode && updatedExportPurchaseRequestUnsaved"
                />
                <app-card
                        :fullBlock="true"
                        colClasses="xl12 lg12 md12 sm12 xs12"
                >
                    <v-form lazy-validation ref="eprForm" v-model="validForm">
                        <v-overlay
                                :value="loader"
                                absolute
                                opacity="0.15"
                        >
                            <v-row>
                                <v-col class="text-center">
                                    <v-progress-circular
                                            color="primary"
                                            indeterminate
                                            size="40"
                                            width="6"
                                    />
                                </v-col>
                            </v-row>
                        </v-overlay>
                        <v-expansion-panels
                                accordion
                                multiple
                                v-model="panel"
                        >
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.general') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <v-layout row class="pt-3" v-if="updateMode">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.exportPurchaseRequestNo') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>{{ Epr__title }}</v-flex>
                                    </v-layout>
                                    <v-layout row :class="addMode ? 'pt-3' : ''">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.date') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-menu
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    max-width="290px"
                                                    min-width="290px"
                                                    ref="eprPreferredEtdMenu"
                                                    transition="scale-transition"
                                                    v-model="eprDateMenu"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                            :label="Epr__eprdate ? '' : $t('message.required')"
                                                            :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                                            :value="computedEprDateFormatted"
                                                            append-icon="event"
                                                            clearable
                                                            class="force-text-left"
                                                            hint="DD/MM/YY format"
                                                            hide-details="auto"
                                                            background-color="white"
                                                            persistent-hint
                                                            solo
                                                            v-on="on"
                                                            @click:clear="Epr__eprdate = null"
                                                            @click:append="eprDateMenu = true"
                                                    />
                                                </template>
                                                <v-date-picker
                                                        first-day-of-week="1"
                                                        locale-first-day-of-year="4"
                                                        show-week
                                                        v-model="Epr__eprdate"
                                                        @input="eprDateMenu = false"
                                                />
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="addMode">
                                        <v-flex xs6 lg5 md6 pb-0 pt-0 offset-lg-2 offset-md-3 offset-xs-6 class="align-center">
                                            <div class="d-flex flex-row">
                                                <v-btn
                                                        @click="openSelectEprDialog()"
                                                        color="success"
                                                        small
                                                        width="190px"
                                                        max-width="190px"
                                                >{{ $t('message.copyPreviousEpr') }}</v-btn>
                                                <span class="ml-3">{{ $t('message.orEnterPoDetailsManuallyBelow') }}</span>
                                            </div>
                                            <div class="d-flex flex-row mt-3">
                                                <v-btn
                                                        @click="openSelectContractDialog()"
                                                        color="success"
                                                        small
                                                        width="190px"
                                                        max-width="190px"
                                                >{{ $t('message.copyPreviousContract') }}</v-btn>
                                                <span class="ml-3">{{ $t('message.orEnterPoDetailsManuallyBelow') }}</span>
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.type') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-select
                                                    :items="filteredSalestypes"
                                                    :label="Epr__salestype_id ? '' : $t('message.required')"
                                                    :rules="[...validationRules.required]"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-text="Salestype.title"
                                                    item-value="Salestype.id"
                                                    solo
                                                    required
                                                    v-model="Epr__salestype_id"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">
                                            {{ $t('message.appTimberCompany') }}
                                        </v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-select
                                                    :items="filteredOffices"
                                                    :label="Epr__office_id ? '' : $t('message.required')"
                                                    :rules="[...validationRules.required]"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-text="Office.title"
                                                    item-value="Office.id"
                                                    required
                                                    solo
                                                    v-model="Epr__office_id"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">
                                            {{ Epr__salestype_id == 5 ? $t('message.seller') : $t('message.preferredSupplier') }}
                                        </v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                    :items=allSuppliers
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-text="Supplier.title"
                                                    item-value="Supplier.id"
                                                    required
                                                    solo
                                                    v-model="Epr__supplier_id"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.countryOfOrigin') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                    :items=allCountries
                                                    :label="Epr__productorigin_id ? '' : $t('message.required')"
                                                    :rules="[...validationRules.required]"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-text="Country.name"
                                                    item-value="Country.id"
                                                    required
                                                    solo
                                                    v-model="Epr__productorigin_id"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.incoterm') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-select
                                                    :items="contractIncoTerms"
                                                    :label="Epr__incoterm_id ? '' : $t('message.required')"
                                                    :rules="[...validationRules.required]"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-value="Incoterm.id"
                                                    item-text="Incoterm.title"
                                                    required
                                                    solo
                                                    v-model="Epr__incoterm_id"
                                                    @change="incotermChanged(val)"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="Epr__incoterm_id == 1">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.pol') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                    :items=allShippingLoadingPorts
                                                    :item-text="item => item.Country.name ? item.Shippingport.title + '  -  ' + item.Country.name : item.Shippingport.title"
                                                    :item-value="'Shippingport.id'"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    required
                                                    v-model="Epr__loadingport_id"
                                            >
                                                <template v-slot:selection="data">
                                                    {{ data.item.Country.name ? data.item.Shippingport.title + '  -  ' + data.item.Country.name : data.item.Shippingport.title }}
                                                </template>
                                                <template v-slot:item="data">
                                                    {{ data.item.Country.name ? data.item.Shippingport.title + '  -  ' + data.item.Country.name : data.item.Shippingport.title }}
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.pod') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                    :items=allShippingDischargePorts
                                                    :item-text="item => item.Country.name ? item.Shippingport.title + '  -  ' + item.Country.name : item.Shippingport.title"
                                                    :item-value="'Shippingport.id'"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    required
                                                    v-model="Epr__shippingport_id"
                                            >
                                                <template v-slot:selection="data">
                                                    {{ data.item.Country.name ? data.item.Shippingport.title + '  -  ' + data.item.Country.name : data.item.Shippingport.title }}
                                                </template>
                                                <template v-slot:item="data">
                                                    {{ data.item.Country.name ? data.item.Shippingport.title + '  -  ' + data.item.Country.name : data.item.Shippingport.title }}
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.eutrComplianceRequired') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-checkbox
                                                :true-value="1"
                                                class="mt-0"
                                                dense
                                                hide-details="auto"
                                                v-model="Epr__eutr_compliance_required"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.supplierPaymentTerms') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                    :items=allSupplierPaymentTerms
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-value="Paymentterm.id"
                                                    item-text="Paymentterm.title"
                                                    solo
                                                    v-model="Epr__popaymentstatement_id"
                                            >
                                                <template v-slot:item="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-sm">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                                <template v-slot:selection="{ item }">
                                                    <div class="d-flex flex-column">
                                                        <span class="font-sm">{{ item.Paymentterm.title }}</span>
                                                    </div>
                                                </template>
                                            </v-autocomplete>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.suggestedETS') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-menu
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    max-width="290px"
                                                    min-width="290px"
                                                    ref="eprPreferredEtdMenu"
                                                    transition="scale-transition"
                                                    v-model="eprPreferredEtsMenu"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                            :value="computedEprPreferredEtsFormatted"
                                                            append-icon="event"
                                                            clearable
                                                            class="force-text-left"
                                                            hide-details="auto"
                                                            hint="DD/MM/YY format"
                                                            background-color="white"
                                                            persistent-hint
                                                            readonly
                                                            solo
                                                            v-on="on"
                                                            @click:clear="Epr__etsdate = null"
                                                            @click:append="eprPreferredEtsMenu = true"
                                                    />
                                                </template>
                                                <v-date-picker
                                                        first-day-of-week="1"
                                                        locale-first-day-of-year="4"
                                                        show-week
                                                        v-model="Epr__etsdate"
                                                        @input="eprPreferredEtsMenu = false"
                                                />
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.shippingWeekStatement') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-text-field
                                                    :value="Epr__shippingweekstatement"
                                                    autocomplete="new-password"
                                                    class="force-text-left"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    solo
                                                    @change="Epr__shippingweekstatement = $event"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.preferredEta') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-menu
                                                    :close-on-content-click="false"
                                                    :nudge-right="40"
                                                    max-width="290px"
                                                    min-width="290px"
                                                    ref="eprPreferredEtaMenu"
                                                    transition="scale-transition"
                                                    v-model="eprPreferredEtaMenu"
                                            >
                                                <template v-slot:activator="{ on }">
                                                    <v-text-field
                                                            :value="computedEprPreferredEtaFormatted"
                                                            append-icon="event"
                                                            clearable
                                                            class="force-text-left"
                                                            hint="DD/MM/YY format"
                                                            hide-details="auto"
                                                            background-color="white"
                                                            persistent-hint
                                                            readonly
                                                            solo
                                                            v-on="on"
                                                            @click:clear="Epr__etadate = null"
                                                            @click:append="eprPreferredEtaMenu = true"
                                                    />
                                                </template>
                                                <v-date-picker
                                                        first-day-of-week="1"
                                                        locale-first-day-of-year="4"
                                                        show-week
                                                        v-model="Epr__etadate"
                                                        @input="eprPreferredEtaMenu = false"
                                                />
                                            </v-menu>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.containers') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <div class="d-flex flex-row">
                                                <v-text-field
                                                        :counter="false"
                                                        :rules="[...validationRules.number, ...validationRules.minimumOne]"
                                                        :value="Epr__containercount"
                                                        :label="Epr__containercount ? '' : $t('message.required')"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        solo
                                                        @change="Epr__containercount = $event"
                                                />
                                                <v-icon small class="mt-1 mx-2">mdi-close</v-icon>
                                                <v-select
                                                        :items="containerSizes"
                                                        :label="Epr__containersize_id ? '' : $t('message.required')"
                                                        clearable
                                                        dense
                                                        hide-details="auto"
                                                        item-text="Containersize.title"
                                                        item-value="Containersize.id"
                                                        solo
                                                        v-model="Epr__containersize_id"
                                                />
                                            </div>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.certification') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-select
                                                    :items=certifications
                                                    :label="Epr__fsc ? '' : $t('message.required')"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-text="Certification.title"
                                                    item-value="Certification.id"
                                                    required
                                                    solo
                                                    v-model="Epr__fsc"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout v-if="false">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.product') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1>
                                            <v-autocomplete
                                                    :items=productGroups
                                                    :label="Epr__productgroup_id ? '' : $t('message.required')"
                                                    :rules="[...validationRules.required]"
                                                    clearable
                                                    dense
                                                    hide-details="auto"
                                                    item-text="Productgroup.title"
                                                    item-value="Productgroup.id"
                                                    solo
                                                    v-model="Epr__productgroup_id"
                                            />
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row>
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.measurementSystem') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1 class="d-flex flex-row">
                                            <v-radio-group
                                                    class="mt-0"
                                                    dense
                                                    hide-details="auto"
                                                    row
                                                    v-model="Epr__ms_id"
                                            >
                                                <v-radio :label="$t('message.metric')" :value="1"/>
                                                <v-radio :label="$t('message.imperial')" :value="2"/>
                                            </v-radio-group>
                                            <div class="font-sm font-italic">({{ $t('message.switchToFilterThicknessAndUnits') }})</div>
                                        </v-flex>
                                    </v-layout>
                                    <v-layout row v-if="updateMode">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.status') }}</v-flex>
                                        <v-flex xs6 lg4 md6 py-1><span :class="Epr__status_id == 3 ? 'green--text lighten-1 font-weight-bold' : ''">{{ getStatus(Epr__status_id) }}</span></v-flex>
                                    </v-layout>
                                    <v-layout row v-if="updateMode && Epr__contract_id != null">
                                        <v-flex xs6 lg2 md3 pl-5 py-1 class="align-center">{{ $t('message.relatedContract') }}</v-flex>
                                        <v-flex xs6 lg3 md5 py-1>
                                            <router-link :to="{name: 'update_contract', params: { contractId: Epr__contract_id }}" class="text-no-wrap">{{ Contract__title }}</router-link>
                                        </v-flex>
                                    </v-layout>
                                    <EprItemList
                                            :item-list-key="itemListKey"
                                            :items-copied="itemsCopied"
                                            :items-loaded="itemsLoaded"
                                            :update-mode="updateMode"
                                            :validate.sync="validateEprItems"
                                            @items-invalid="setItemsValidity(false)"
                                            @items-valid="setItemsValidity(true)"
                                    />
                                    <div class="pl-3">{{ $t('message.remarks') + ' (' + $t('message.shownInContract') + ')' }}</div>
                                    <v-lazy>
                                        <v-textarea
                                                :value="Epr__remarks"
                                                auto-grow
                                                clearable
                                                class="cyan lighten-5 col-lg-6 col-md-6 col-xs-12 ml-3"
                                                dense
                                                hide-details="auto"
                                                key="epr-contract-remarks"
                                                rows="1"
                                                solo
                                                @change="Epr__remarks = $event"
                                        />
                                    </v-lazy>
                                    <div class="pl-3 pt-3">{{ $t('message.remarksInternal') }}</div>
                                    <v-lazy>
                                        <v-textarea
                                                :value="Epr__internal_remarks"
                                                auto-grow
                                                clearable
                                                class="cyan lighten-5 col-lg-6 col-md-6 col-xs-12 ml-3"
                                                dense
                                                hide-details="auto"
                                                key="epr-internal-remarks"
                                                rows="1"
                                                solo
                                                @change="Epr__internal_remarks = $event"
                                        />
                                    </v-lazy>
                                    <div class="pl-3 pt-3">{{ $t('message.gradeRemarks') + ' ('+ $t('message.internal') + ')' }}</div>
                                    <v-lazy>
                                        <v-textarea
                                                :value="Epr__grade_remarks"
                                                auto-grow
                                                clearable
                                                class="cyan lighten-5 col-lg-6 col-md-6 col-xs-12 ml-3"
                                                dense
                                                hide-details="auto"
                                                key="epr-grade-remarks"
                                                rows="1"
                                                solo
                                                @change="Epr__grade_remarks = $event"
                                        />
                                    </v-lazy>
                                    <div class="pl-3 pt-3">{{ $t('message.sizeRemarks') + ' ('+ $t('message.internal') + ')' }}</div>
                                    <v-lazy>
                                        <v-textarea
                                                :value="Epr__size_remarks"
                                                auto-grow
                                                clearable
                                                class="cyan lighten-5 col-lg-6 col-md-6 col-xs-12 ml-3"
                                                dense
                                                hide-details="auto"
                                                key="epr-size-remarks"
                                                rows="1"
                                                solo
                                                @change="Epr__size_remarks = $event"
                                        />
                                    </v-lazy>
                                    <div class="pl-1">
                                        <div class="pl-2 mt-5" v-if="showRelatedFiles">{{ $t('message.relatedFiles') }}</div>
                                        <FileUploadList
                                                :file-name-prefix="fileNamePrefix"
                                                :files="files"
                                                :page-key="fileListPageKey"
                                                :updateMode="this.updateMode"
                                                module="epr"
                                                class="pt-2"
                                                v-if="showRelatedFiles"
                                                @upload-file-done="addUploadedFile"
                                        />
                                    </div>
                                    <v-layout row>
                                        <v-flex d-flex class="align-center">
                                            <v-layout row pl-3 pt-4 mb-0 ref="actionsRow">
                                                <v-btn
                                                        class="ml-3"
                                                        small
                                                        @click.stop="previewDialog = true"
                                                >{{ $t('message.preview') }}
                                                </v-btn>
<!--                                                <v-btn-->
<!--                                                        small-->
<!--                                                        class="ml-3 lighten-2"-->
<!--                                                        v-if="updateMode"-->
<!--                                                        @click.stop="viewPdf()"-->
<!--                                                >-->
<!--                                                    <v-icon small>far fa-file-pdf</v-icon>-->
<!--                                                    <span class="ml-1">{{ $t('message.pdf') }}</span>-->
<!--                                                </v-btn>-->
<!--                                                <v-btn-->
<!--                                                        small-->
<!--                                                        class="ml-3 lighten-2"-->
<!--                                                        v-if="updateMode && [3].includes(Epr__status_id)"-->
<!--                                                        @click.stop="viewPdf('purchaseOrder')"-->
<!--                                                >-->
<!--                                                    <v-icon small>far fa-file-pdf</v-icon>-->
<!--                                                    <span class="ml-1">{{ $t('message.po') }}</span>-->
<!--                                                </v-btn>-->
                                                <PrintExportPurchaseOrderButton
                                                    :update-mode="updateMode"
                                                    :list-mode="false"
                                                    :epr-id="Epr__id"
                                                    :epr-status-id="Epr__status_id"
                                                />
                                                <v-btn
                                                        :loading="loading.add"
                                                        class="ml-3"
                                                        color="info"
                                                        small
                                                        @click="addEpr()"
                                                        v-if="addMode == true && $can('create','Epr')"
                                                >{{ $t('message.create') }}
                                                </v-btn>
                                                <v-btn
                                                        :loading="loading.save"
                                                        class="ml-3"
                                                        color="info"
                                                        small
                                                        @click="saveEpr()"
                                                        v-if="updateMode == true && [1,2].includes(Epr__status_id) && $can('update','Epr')"
                                                >
                                                    {{ $t('message.save') }}
                                                </v-btn>
                                                <v-btn
                                                        :loading="loading.save"
                                                        class="ml-3"
                                                        color="info"
                                                        small
                                                        @click="saveEpr()"
                                                        v-if="updateMode == true && [3].includes(Epr__status_id) && $can('update','Epr') && Epr__contract_id == null"
                                                >
                                                    {{ $t('message.save') }}
                                                </v-btn>
                                                <v-btn
                                                        :loading="loading.cancel"
                                                        class="ml-3"
                                                        color="error"
                                                        small
                                                        v-if="updateMode == true && [1].includes(Epr__status_id) && $can('delete','Epr')"
                                                        @click="cancelEpr(Epr__id)"
                                                >
                                                    {{ $t('message.cancel') }}
                                                </v-btn>
                                                <v-btn
                                                        :loading="loading.submit"
                                                        class="ml-3"
                                                        color="warning"
                                                        small
                                                        v-if="updateMode == true && Epr__status_id == 1 && $can('update','Epr')"
                                                        @click="submitEprForApproval(Epr__id)"
                                                >{{ $t('message.submitToOperations') }}
                                                </v-btn>
                                                <v-btn
                                                        :loading="loading.approve"
                                                        color="success"
                                                        class="ml-3"
                                                        small
                                                        v-if="updateMode == true && Epr__status_id == 2 && $can('approve','Epr')"
                                                        @click="approveEpr(Epr__id)"
                                                >{{ $t('message.approve') }}
                                                </v-btn>
                                                <v-btn
                                                        :loading="loading.reject"
                                                        class="ml-3"
                                                        color="error"
                                                        small
                                                        v-if="updateMode == true && Epr__status_id == 2 && $can('update','Epr')"
                                                        @click="rejectEpr(Epr__id)"
                                                >{{ $t('message.reject') }}
                                                </v-btn>
                                                <v-btn
                                                        class="ml-3"
                                                        color="info"
                                                        small
                                                        v-if="updateMode == true && Epr__status_id == 3 && Epr__contract_id == null && $can('approve','Epr')"
                                                        @click.stop="convertDialog = true"
                                                >{{ $t('message.convertToContract') }}
                                                </v-btn>
                                                <v-btn
                                                        class="ml-3"
                                                        small
                                                        v-if="addMode == false && [2].includes(Epr__status_id) && $can('create','Epr')"
                                                        @click="newEpr()"
                                                >{{ $t('message.createAnotherRequest') }}</v-btn>
                                                <v-btn
                                                        class="ml-3"
                                                        small
                                                        @click="eprList()"
                                                        v-if="addMode == false && [1,2,3].includes(Epr__status_id) && $can('access','Eprs')"
                                                >{{ $t('message.seeRequestsList') }}</v-btn>
                                            </v-layout>
                                        </v-flex>
                                    </v-layout>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel>
                                <v-expansion-panel-header class="success lighten-2" @click="panel=[]">{{ $t('message.operationsRemarks') }}</v-expansion-panel-header>
                                <v-expansion-panel-content class="dense-inputs">
                                    <EprRemarks :epr-id="Epr__id" :epr-status-id="Epr__status_id"/>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                            <v-expansion-panel :disabled="$route.matched.some(({name}) => name == 'add_epr')">
                                <v-expansion-panel-header class="success lighten-2" ripple @click="panel=[]">{{ $t('message.history') }}</v-expansion-panel-header>
                                <v-expansion-panel-content>
                                    <DocumentHistory asset="eprs" :asset_id="Epr__id"/>
                                </v-expansion-panel-content>
                            </v-expansion-panel>
                        </v-expansion-panels>
                    </v-form>
                </app-card>
            </v-layout>
        </v-container>
        <SimpleAlert
                :alert_message.sync="dialogs.error_message"
                :dialog.sync="dialogs.error"
                @dialog-closed="dialogClosed"
        />
        <v-dialog
                overlay=false
                scrollable
                transition="dialog-bottom-transition"
                v-model="previewDialog"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.contractRequestPreview') }}</v-card-title>
                <v-card-text class="pa-3">
                    <ExportPurchaseRequestPreview/>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3">
                    <v-spacer/>
                    <v-btn
                        class="ml-3"
                        color="default"
                        small
                        @click="previewDialog = false"
                    >{{ [1,2].includes(Epr__status_id) ? $t('message.backToEditing') : $t('message.close') }}
                    </v-btn>
                    <v-btn
                            :loading="loading.add"
                            class="ml-3"
                            color="info"
                            small
                            v-if="addMode == true && $can('create','Epr')"
                            @click="addEpr()"
                    >{{ $t('message.create') }}
                    </v-btn>
                    <v-btn
                            :loading="loading.save"
                            class="ml-3"
                            color="info"
                            small
                            v-if="addMode == false && [1,2].includes(Epr__status_id) && $can('update','Epr')"
                            @click="saveEpr()"
                    >{{ $t('message.save') }}
                    </v-btn>
                    <v-btn
                            :loading="loading.save"
                            class="ml-3"
                            color="info"
                            small
                            v-if="updateMode == true && [3].includes(Epr__status_id) && $can('update','Epr') && Epr__contract_id == null"
                            @click="saveEpr()"
                    >{{ $t('message.save') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
                persistent
                scrollable
                transition="dialog-top-transition"
                v-model="selectContractDialog"
                width="1200px"
                max-width="1200px !important"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.selectContract') }}</v-card-title>
                <v-card-text class="pa-3">
                    <v-overlay
                            :value="loading.contracts"
                            absolute
                            opacity="0.15"
                    >
                        <v-row>
                            <v-col class="text-center">
                                <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                />
                            </v-col>
                        </v-row>
                    </v-overlay>
                    <v-data-table
                            :footer-props="{
                                itemsPerPageOptions: [10,20,50,-1],
                                showCurrentPage: true,
                                showFirstLastPage: true
                            }"
                            :headers="contractHeaders"
                            :items="filteredContracts"
                            :search="searchTerm"
                            :options.sync="tableOptions"
                            calculate-widths
                            class="appic-table-light specification-table"
                            dense
                            item-key="Contract.id"
                            show-select
                            single-select
                            v-model="selectedContract"
                    >
                        <template v-slot:top>
                            <v-row no-gutters>
                                <v-col cols="6" class="d-flex flex-row col-lg-6 col-sm-12 offset-lg-6 align-center pb-3">
                                    <v-text-field
                                            :label="$t('message.filterResults')"
                                            :value="searchTerm"
                                            autocomplete="off"
                                            class="force-text-left mt-2 mb-0"
                                            dense
                                            prepend-inner-icon="mdi-filter-outline"
                                            hide-details
                                            @change="searchTerm = $event"
                                    />
                                    <v-btn
                                            :loading="loading.filterResults"
                                            class="ml-3"
                                            small
                                            @click="filterResults"
                                    >{{ $t('message.filter') }}
                                    </v-btn>
                                    <v-btn
                                            class="ml-3"
                                            small
                                            @click="searchTerm = null"
                                    >
                                        <v-icon>refresh</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:header.data-table-select="{ on, props }">
                            <v-simple-checkbox v-bind="props" v-on="on"/>
                        </template>
                        <template v-slot:item.data-table-select="{ isSelected, select, item }">
                            <div class="pt-1 text-center">
                                <v-simple-checkbox :value="isSelected" @input="select($event)"/>
                            </div>
                        </template>
                        <template v-slot:item.Contract.title="{ item }">
                            <span class="font-weight-bold">{{ item.Contract.version != null ? [item.Contract.title, item.Contract.revision_no , item.Contract.partial_no].filter(Boolean).join('-') : item.Contract.title }}</span>
                        </template>
                        <template v-slot:item.Contract.contractdate="{ item }">
                            {{ item.Contract.contractdate != '0000-00-00' ? formatDate(item.Contract.contractdate) : '' }}
                        </template>
                        <template v-slot:item.Contract.Customer.title="{ item }">
                            {{ item.Contract.Customer.otsname != '' ? item.Contract.Customer.otsname : item.Contract.Customer.title }}
                        </template>
                        <template v-slot:item.Contract.Supplier.title="{ item }">
                            <span>{{ item.Contract.Supplier.code != '' ? item.Contract.Supplier.code : item.Contract.Supplier.title }}</span>
                        </template>
                        <template v-slot:item.Contract.species="{ item }">
                            <div class="text-no-wrap truncate" v-if="item.Contract.version == null">{{ item.Contract.species }}</div>
                            <div v-if="item.Contract.version != null">
                                <div :class="JSON.parse(item.Contract.species_ids).length > 1 ? 'font-xs ' : ''" v-for="species in contractSpecies(JSON.parse(item.Contract.species_ids)) ">{{ species }}</div>
                                <div v-if="item.Contract.species_ids == null">{{ item.Contract.species }}</div>
                            </div>
                        </template>
                        <template v-slot:item.Contract.containersize_id="{ item }">
                            <div class="text-no-wrap">
                                <span v-if=" [1,2,3,5].includes(item.Contract.containersize_id) ">{{ item.Contract.containercount }}</span>
                                <span v-if=" [1,2,3,5].includes(item.Contract.containersize_id) "> X </span>
                                <span>{{ getContainerSize(item.Contract.containersize_id) }}</span>
                            </div>
                        </template>
                        <template v-slot:item.Contract.cost="{ item }">
                            <div class="flex-row">
                                <span class="pr-1">{{ currency(item.Contract.currency_id) }}</span>
                                <span class="text-right">{{ formatThisNumber(item.Contract.cost) }}</span>
                            </div>
                        </template>
                        <template v-slot:item.Contract.amount="{ item }">
                            <div class="flex-row text-left">
                                <span class="pr-1">{{ currency(item.Contract.currency_id) }}</span>
                                <span class="text-right">{{ formatThisNumber(item.Contract.amount) }}</span>
                            </div>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3">
                    <v-spacer/>
                    <v-btn
                        class="ml-3"
                        color="default"
                        small
                        @click="selectContractDialog = false"
                    >
                        {{ $t('message.backToEditing') }}
                    </v-btn>
                    <v-btn
                        :disabled="selectedContract.length == 0"
                        :loading="loading.copy"
                        color="success lighten-1"
                        small
                        @click="copyContract()"
                    >
                        {{ $t('message.copy') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
            persistent
            scrollable
            transition="dialog-top-transition"
            v-model="selectEprDialog"
            width="1000px"
            max-width="1000px !important"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.selectEpr') }}</v-card-title>
                <v-card-text class="pa-3">
                    <v-overlay
                            :value="loading.eprs"
                            absolute
                            opacity="0.15"
                    >
                        <v-row>
                            <v-col class="text-center">
                                <v-progress-circular
                                        color="primary"
                                        indeterminate
                                        size="40"
                                        width="6"
                                />
                            </v-col>
                        </v-row>
                    </v-overlay>
                    <v-data-table
                            :footer-props="{
                                itemsPerPageOptions: [10,20,50,-1],
                                showCurrentPage: true,
                                showFirstLastPage: true
                            }"
                            :headers="eprHeaders"
                            :items="filteredEprs"
                            :search="searchEprTerm"
                            :options.sync="eprTableOptions"
                            calculate-widths
                            class="appic-table-light specification-table"
                            dense
                            item-key="Epr.id"
                            show-select
                            single-select
                            sort-by="Epr.title"
                            sort-desc
                            v-model="selectedEpr"
                    >
                        <template v-slot:top>
                            <v-row no-gutters>
                                <v-col cols="6" class="d-flex flex-row col-lg-6 col-sm-12 offset-lg-6 align-center pb-3">
                                    <v-text-field
                                            :label="$t('message.filterResults')"
                                            :value="searchEprTerm"
                                            autocomplete="off"
                                            class="force-text-left mt-2 mb-0"
                                            dense
                                            prepend-inner-icon="mdi-filter-outline"
                                            hide-details
                                            @change="searchEprTerm = $event"
                                    />
                                    <v-btn
                                            :loading="loading.filterEprResults"
                                            class="ml-3"
                                            small
                                            @click="filterEprResults"
                                    >{{ $t('message.filter') }}
                                    </v-btn>
                                    <v-btn
                                            class="ml-3"
                                            small
                                            @click="searchTerm = null"
                                    >
                                        <v-icon>refresh</v-icon>
                                    </v-btn>
                                </v-col>
                            </v-row>
                        </template>
                        <template v-slot:header.data-table-select="{ on, props }">
                            <v-simple-checkbox v-bind="props" v-on="on"/>
                        </template>
                        <template v-slot:item.data-table-select="{ isSelected, select, item }">
                            <div class="pt-1 text-center">
                                <v-simple-checkbox :value="isSelected" @input="select($event)"/>
                            </div>
                        </template>
                        <template v-slot:item.Epr.title="{ item }">
                            <span class="font-weight-bold">{{ item.Epr.title }}</span>
                        </template>
                        <template v-slot:item.Epr.eprdate="{ item }">
                            {{ item.Epr.eprdate != '0000-00-00' ? formatDate(item.Epr.eprdate) : '' }}
                        </template>
                        <template v-slot:item.Epr.Supplier.code="{ item }">
                            <span>{{ item.Epr.Supplier.otsname }}</span>
                        </template>
                        <template v-slot:item.Epr.item_species="{ item }">
                            <v-row v-for="(specification, idx) in item.Epr.Specifications" no-gutters v-bind:key="idx">
                                <v-col class="text-no-wrap" :class="idx > 0 ? 'border-top-1' : ''">{{ specification.species }}</v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.Epr.item_grade="{ item }">
                            <v-row v-for="(specification, idx) in item.Epr.Specifications" no-gutters v-bind:key="idx">
                                <v-col class="text-no-wrap" :class="idx > 0 ? 'border-top-1' : ''">{{ specification.grade }}</v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.Epr.item_qty="{ item }">
                            <v-row v-for="(specification, idx) in item.Epr.Specifications" no-gutters v-bind:key="idx">
                                <v-col :class="idx > 0 ? 'border-top-1' : ''">{{ formatThisNumber(specification.volume,'0,0.00') + specification.uofm  }}</v-col>
                            </v-row>
                        </template>
                        <template v-slot:item.Epr.cost="{ item }">
                            <div class="flex-row">
                                <span class="pr-1">{{ currency(item.Epr.currency_id) }}</span>
                                <span class="text-right">{{ formatThisNumber(item.Epr.cost) }}</span>
                            </div>
                        </template>
                    </v-data-table>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3">
                    <v-spacer></v-spacer>
                    <v-btn
                            class="ml-3"
                            color="default"
                            small
                            @click="selectEprDialog = false"
                    >
                        {{ $t('message.backToEditing') }}
                    </v-btn>
                    <v-btn
                            :disabled="selectedEpr.length == 0"
                            :loading="loading.copyEpr"
                            color="success lighten-1"
                            small
                            @click="copyEpr()"
                    >
                        {{ $t('message.copy') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
                overlay=false
                scrollable
                transition="dialog-bottom-transition"
                v-if="Epr__status_id == 3"
                v-model="convertDialog"
                width="600px"
        >
            <v-form ref="convertToContractForm" v-model="validConvertForm">
                <v-card>
                    <v-card-title class="success white--text">{{ $t('message.eprConvertThisToContract') }}</v-card-title>
                    <v-card-text>
                        <v-row no-gutters class="pt-3 align-center">
                            <v-col cols="4">{{ $t('message.newContractNumber') }}</v-col>
                            <v-col cols="8">
                                <v-text-field
                                        :value="newContractNo"
                                        :rules="[...validationRules.required, ...validationRules.plainTextWithNumberAndSymbols]"
                                        autocomplete="new-password"
                                        class="force-text-left"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        solo
                                        @change="newContractNo = $event"
                                />
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="pt-1 align-center">
                            <v-col cols="4">{{ $t('message.buyer') }}</v-col>
                            <v-col cols="8">
                                <v-autocomplete
                                        :items=filteredBuyers
                                        :rules="[...validationRules.required]"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-text="Customer.otsname"
                                        item-value="Customer.id"
                                        required
                                        solo
                                        v-model="newContractBuyer"
                                        @change="buyerChanged()"
                                />
                            </v-col>
                            <v-row no-gutters class="pt-1 align-center">
                                <v-col cols="4">{{ $t('message.salesColleague') }}</v-col>
                                <v-col cols="8">
                                    <v-autocomplete
                                            :items="filteredSalesColleagues"
                                            :rules="[...validationRules.required]"
                                            clearable
                                            dense
                                            hide-details="auto"
                                            item-text="Salesperson.name"
                                            item-value="Salesperson.id"
                                            solo
                                            v-model="newContractSalesColleague"
                                    >
                                        <template v-slot:item="data">
                                            <template>
                                                <v-list-item-avatar>
                                                    <img :src="data.item.User.photo ? ('data:image/jpeg;base64,' + data.item.User.photo) : '/static/avatars/appic_user.jpg'" alt="avatar" height="35" width="35" class="img-responsive rounded-circle">
                                                </v-list-item-avatar>
                                                <v-list-item-content>
                                                    <v-list-item-title v-html="data.item.Salesperson.name"></v-list-item-title>
                                                    <v-list-item-subtitle v-html="data.item.Country.name"></v-list-item-subtitle>
                                                </v-list-item-content>
                                            </template>
                                        </template>
                                    </v-autocomplete>
                                </v-col>
                            </v-row>
                        </v-row>
                        <v-row no-gutters class="pt-1 align-center">
                            <v-col cols="4">{{ $t('message.buyerPaymentTerms') }}</v-col>
                            <v-col cols="8">
                                <v-autocomplete
                                        :items="allBuyerPaymentTerms"
                                        :rules="[...validationRules.required]"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        item-value="Paymentterm.id"
                                        item-text="Paymentterm.title"
                                        required
                                        solo
                                        v-model="newContractBuyerTerm"
                                >
                                    <template v-slot:item="{ item }">
                                        <div class="d-flex flex-column">
                                            <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                            <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                        </div>
                                    </template>
                                    <template v-slot:selection="{ item }">
                                        <div class="d-flex flex-column">
                                            <span class="font-xs">{{ item.Paymentterm.non_standard == 1 ? $t('message.nonStandard') : $t('message.standard') }}</span>
                                            <span class="font-sm font-weight-bold">{{ item.Paymentterm.title }}</span>
                                        </div>
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="pt-1 align-center">
                            <v-col cols="4">{{ $t('message.pod') }}</v-col>
                            <v-col cols="8">
                                <v-autocomplete
                                        :items="allShippingDischargePorts"
                                        :item-text="item => item.Country.name ? item.Shippingport.title + '  -  ' + item.Country.name : item.Shippingport.title"
                                        :item-value="'Shippingport.id'"
                                        :rules="[...validationRules.required]"
                                        clearable
                                        dense
                                        hide-details="auto"
                                        solo
                                        required
                                        v-model="newContractPod"
                                >
                                    <template v-slot:selection="data">
                                        {{ data.item.Country.name ? data.item.Shippingport.title + ' - ' + data.item.Country.name : data.item.Shippingport.title }}
                                    </template>
                                    <template v-slot:item="data">
                                        {{ data.item.Country.name ? data.item.Shippingport.title + ' - ' + data.item.Country.name : data.item.Shippingport.title }}
                                    </template>
                                </v-autocomplete>
                            </v-col>
                        </v-row>
                        <v-row no-gutters class="pt-3">
                            <v-col class="red--text font-italic">{{ $t('message.contractConvertNote') }}</v-col>
                        </v-row>
                    </v-card-text>
                    <v-card-actions class="pr-3 pt-3">
                        <v-spacer/>
                        <v-btn
                                class="ml-3"
                                color="default"
                                small
                                @click="convertDialog = false"
                        >{{ $t('message.cancel') }}
                        </v-btn>
                        <v-btn
                                :loading="loading.convert"
                                class="ml-3"
                                color="info"
                                small
                                @click="convertEprToContract(Epr__id)"
                        >{{ $t('message.convert') }}
                        </v-btn>
                    </v-card-actions>
                </v-card>
            </v-form>
        </v-dialog>
    </div>
</template>

<script>
import {mapFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {mapFields} from "vuex-map-fields";
import {mapActions, mapGetters} from "vuex";
import {formatDate, isObject, numberFormat, scrollToFirstFormValidationError} from "Helpers/helpers";
import {api} from "Api";
import router from "@/router";
import {v4 as uuidv4} from "uuid";
import EprItemList from "Components/Appic/EprItemList";
import FileUploadList from "Components/Appic/FileUploadList";


const EprRemarks = () => import("Components/Appic/EprRemarks");
const DocumentHistory = () => import("Components/Appic/DocumentHistory");
const ExportPurchaseRequestPreview = () => import("Components/Appic/ExportPurchaseRequestPreview");
// const FileUploadList = () => import("../../../components/Appic/FileUploadList");
const SimpleAlert = () => import("Components/Appic/SimpleAlert");
const EprAlert = () => import("Components/Appic/EprAlert");
const PrintExportPurchaseOrderButton = () => import("Components/Appic/Printing/PrintExportPurchaseOrderButton");

export default {
    name: "AddEpr",
    components: {
        DocumentHistory,
        EprAlert,
        EprItemList,
        EprRemarks,
        ExportPurchaseRequestPreview,
        FileUploadList,
        PrintExportPurchaseOrderButton,
        SimpleAlert
    },
    props: ['eprId'],
    data(){
        return {
            convertDialog: false,
            dialogs: {
                error:  false,
                error_message: ""
            },
            eprTableOptions: {
                page: 1
            },
            fileListPageKey: 0,
            filteredBuyers: [],
            filteredContracts: [],
            filteredEprs: [],
            filteredSalesColleagues: [],
            filteredSalestypes: [
                {Salestype: {id: 1, title: 'Direct Sales', code: 'DIR'}}
            ],
            incotermports: [
                {value: null, title: 'None'},
                {value: 0, title: 'POD'},
                {value: 1, title: 'POL'},
                {value: 2, title: this.$t('message.doNotShow')},
            ],
            itemListKey: 0,
            itemsCopied: false,
            itemsLoaded: false,
            loading: {
                add: false,
                approve: false,
                buyers: false,
                copy: false,
                copyEpr: false,
                contracts: false,
                convert: false,
                eprs: false,
                filterResults: null,
                filterEprResults: null,
                reset: false,
                reject: false,
                save: false,
                submit: false,
            },
            loader: false,
            eprDateMenu: false,
            eprPreferredEtaMenu: false,
            eprPreferredEtdMenu: false,
            eprPreferredEtsMenu: false,
            newContractNo: null,
            newContractBuyer: null,
            newContractBuyerTerm: null,
            newContractPod: null,
            newContractSalesColleague: null,
            panel: [0, 1],
            pageKey: 0,
            previewDialog: false,
            searchEprTerm: null,
            searchTerm: null,
            selectContractDialog: false,
            selectEprDialog: false,
            selectedContract: [],
            selectedEpr: [],
            showRelatedFiles: false,
            tableOptions: {
                page: 1
            },
            validateEprItems: false,
            validEprItems: false,
            validForm: true,
            validConvertForm: true
        }
    },
    computed: {
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapFieldsPrefixed('epr',{
            Epr__Salescontact__name: 'Epr.Salescontact.name',
            Epr__containercount: 'Epr.containercount',
            Epr__containersize_id: 'Epr.containersize_id',
            Epr__contract_id: 'Epr.contract_id',
            Epr__currency_id: 'Epr.currency_id',
            Epr__customer_id: 'Epr.customer_id',
            Epr__destination_id: 'Epr.destination_id',
            Epr__documents: 'Epr.documents',
            Epr__etadate: 'Epr.etadate',
            Epr__etddate: 'Epr.etddate',
            Epr__etsdate: 'Epr.etsdate',
            Epr__eutr_compliance_required: 'Epr.eutr_compliance_required',
            Epr__fsc: 'Epr.fsc',
            Epr__grade_remarks: 'Epr.grade_remarks',
            Epr__id: 'Epr.id',
            Epr__incoterm_id: 'Epr.incoterm_id',
            Epr__incotermport: 'Epr.incotermport',
            Epr__internal_remarks: 'Epr.internal_remarks',
            Epr__is_export: 'Epr.is_export',
            Epr__loadingport_id: 'Epr.loadingport_id',
            Epr__ms_id: 'Epr.ms_id',
            Epr__eprdate: 'Epr.eprdate',
            Epr__office_id: 'Epr.office_id',
            Epr__paymentstatement_id: 'Epr.paymentstatement_id',
            Epr__popaymentstatement_id: 'Epr.popaymentstatement_id',
            Epr__productgroup_id: 'Epr.productgroup_id',
            Epr__productorigin_id: 'Epr.productorigin_id',
            Epr__remarks: 'Epr.remarks',
            Epr__salescontact_id: 'Epr.salescontact_id',
            Epr__salestype_id: 'Epr.salestype_id',
            Epr__shippingport_id: 'Epr.shippingport_id',
            Epr__shippingweekstatement: 'Epr.shippingweekstatement',
            Epr__size_remarks: 'Epr.size_remarks',
            Epr__status_id: 'Epr.status_id',
            Epr__supplier_id: 'Epr.supplier_id',
            Epr__title: 'Epr.title',
            Contract__title: 'Contract.title'
        },'statePrefix'),
        ...mapFields('epr',{
            newExportPurchaseRequestSaved: 'newExportPurchaseRequestSaved',
            newExportPurchaseRequestUnsaved: 'newExportPurchaseRequestUnsaved',
            updatedExportPurchaseRequestSaved: 'updatedExportPurchaseRequestSaved',
            updatedExportPurchaseRequestUnsaved: 'updatedExportPurchaseRequestUnsaved'
        }),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapGetters('buyer', {
            allBuyers: 'allBuyers',
            findBuyerById: 'findBuyerById'
        }),
        ...mapGetters('country',{
            allCountries: 'allCountries'
        }),
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        ...mapGetters('document',{
            allNewExportPurchaseRequestDocuments: 'allNewExportPurchaseRequestDocuments',
            allExportPurchaseRequestDocuments: 'allExportPurchaseRequestDocuments',
        }),
        ...mapGetters('incoterm', {
            contractIncoTerms: 'contractIncoTerms'
        }),
        ...mapGetters('paymentterm', {
            allBuyerPaymentTerms: 'allBuyerPaymentTerms',
            allSupplierPaymentTerms: 'allSupplierPaymentTerms'
        }),
        ...mapGetters('salescolleague', {
            allSalesColleagues: 'allSalesColleagues'
        }),
        ...mapGetters('shippingport', {
            allShippingDischargePorts: 'allShippingDischargePorts',
            allShippingLoadingPorts: 'allShippingLoadingPorts'
        }),
        ...mapGetters('species', {
            allSpecies: 'allSpecies',
            findSpeciesById: 'findSpeciesById'
        }),
        ...mapGetters('supplier', {
            allSuppliers: 'allSuppliers',
            findSupplierById: 'findSupplierById'
        }),
        ...mapGetters('user',{
            User: 'User'
        }),
        ...mapGetters([
            'certifications',
            'containerSizes',
            'currencies',
            'incoterms',
            'eprStatuses',
            'offices',
            'productGroups',
            'salesTypes',
            'validationRules'
        ]),
        addMode: function () {
            return this.$route.matched.some(({name}) => name === 'add_epr')
        },
        computedEprDateFormatted() {
            return formatDate(this.Epr__eprdate)
        },
        computedEprPreferredEtaFormatted() {
            return formatDate(this.Epr__etadate)
        },
        computedEprPreferredEtdFormatted() {
            return formatDate(this.Epr__etddate)
        },
        computedEprPreferredEtsFormatted() {
            return formatDate(this.Epr__etsdate)
        },
        contractHeaders() {
            return [
                {
                    id: 1,
                    text: this.$t('message.salesColleague'),
                    value: 'Contract.Salesperson.name',
                    sortable: true,
                    searchable: true,
                    class: 'grey lighten-3 pa-1 text-no-wrap'
                },
                {
                    id: 2,
                    text: this.$t('message.contractNo'),
                    value: 'Contract.title',
                    sortable: true,
                    searchable: true,
                    class: 'grey lighten-3 pa-1 text-no-wrap'
                },
                {
                    id: 3,
                    text: this.$t('message.date'),
                    value: 'Contract.contractdate',
                    sortable: true,
                    searchable: false,
                    class: 'grey lighten-3 pa-1 text-no-wrap'
                },
                {
                    id: 4,
                    text: this.$t('message.buyer'),
                    value: 'Contract.Customer.title',
                    sortable: true,
                    searchable: true,
                    class: 'grey lighten-3 pa-1 text-no-wrap'
                },
                {
                    id: 5,
                    text: this.$t('message.supplier'),
                    value: 'Contract.Supplier.title',
                    sortable: true,
                    searchable: true,
                    class: 'grey lighten-3 pa-1 text-no-wrap'
                },
                {
                    id: 6,
                    text: this.$t('message.species'),
                    value: 'Contract.species',
                    sortable: true,
                    searchable: true,
                    class: 'grey lighten-3 pa-1 text-no-wrap'
                },
                {
                    id: 7,
                    text: this.$t('message.grade'),
                    value: 'Contract.grade',
                    sortable: true,
                    searchable: true,
                    class: 'grey lighten-3 pa-1 text-no-wrap'
                },
                {
                    id: 8,
                    text: this.$t('message.size'),
                    value: 'Contract.size',
                    sortable: true,
                    searchable: true,
                    class: 'grey lighten-3 pa-1 text-no-wrap'
                },
                {
                    id: 9,
                    text: this.$t('message.qty'),
                    value: 'Contract.containersize_id',
                    sortable: false,
                    searchable: false,
                    class: 'grey lighten-3 pa-1 text-no-wrap'
                },
                {
                    id: 10,
                    text: this.$t('message.cost'),
                    value: 'Contract.cost',
                    sortable: false,
                    searchable: false,
                    class: 'grey lighten-3 pa-1 text-no-wrap'
                },
                {
                    id: 11,
                    text: this.$t('message.value'),
                    value: 'Contract.amount',
                    sortable: false,
                    searchable: false,
                    class: 'grey lighten-3 pa-1 text-no-wrap'
                },
            ]
        },
        eprHeaders() {
            return [
                {
                    id: 1,
                    text: this.$t('message.eprNo'),
                    value: 'Epr.title',
                    sortable: true,
                    searchable: true,
                    class: 'grey lighten-3 pa-1 text-no-wrap'
                },
                {
                    id: 2,
                    text: this.$t('message.date'),
                    value: 'Epr.eprdate',
                    sortable: true,
                    searchable: true,
                    class: 'grey lighten-3 pa-1 text-no-wrap'
                },
                {
                    id: 3,
                    text: this.$t('message.supplier'),
                    value: 'Epr.Supplier.code',
                    sortable: true,
                    searchable: true,
                    class: 'grey lighten-3 pa-1 text-no-wrap'
                },
                {
                    id: 4,
                    text: this.$t('message.species'),
                    value: 'Epr.item_species',
                    sortable: true,
                    searchable: true,
                    class: 'grey lighten-3 pa-1 text-no-wrap'
                },
                {
                    id: 5,
                    text: this.$t('message.grade'),
                    value: 'Epr.item_grade',
                    sortable: true,
                    searchable: true,
                    class: 'grey lighten-3 pa-1 text-no-wrap'
                },
                {
                    id: 6,
                    text: this.$t('message.qty'),
                    value: 'Epr.item_qty',
                    sortable: true,
                    searchable: true,
                    class: 'grey lighten-3 pa-1 text-no-wrap'
                },
                {
                    id: 7,
                    text: this.$t('message.cost'),
                    value: 'Epr.cost',
                    sortable: true,
                    searchable: true,
                    class: 'grey lighten-3 pa-1 text-no-wrap'
                }
            ]
        },
        filteredIncoterms() {
            return this.incoterms.filter(i => {
                return [5,9].includes(i.Incoterm.id) == false
            })
        },
        filteredOffices() {
            return this.offices.filter(i => {
                return [1].includes(i.Office.id) == true
            })
        },
        fileNamePrefix() {
            let prefix = ''
            if(this.Epr__title != null && this.Epr__title != ''){
                prefix = this.Epr__title
            }
            return prefix
        },
        files () {
            let files = [];
            if(this.addMode){
                files = this.allNewExportPurchaseRequestDocuments
            } else if(this.updateMode){
                files = this.allExportPurchaseRequestDocuments
            }
            return files
        },
        updateMode: function () {
            return this.$route.matched.some(({name}) => name === 'update_epr')
        },
    },
    methods: {
        ...mapActions('buyer', {
            getAllActiveBuyers: 'getAllActiveBuyers'
        }),
        ...mapActions('country', {
            getAllCountries: 'getAllCountries'
        }),
        ...mapActions('currency', {
            getAllCurrencies: 'getAllCurrencies'
        }),
        ...mapActions('document',{
            resetAllDocuments: 'resetAllDocuments',
            getExportPurchaseRequestDocumentsById: 'getExportPurchaseRequestDocumentsById'
        }),
        ...mapActions('epr',{
            approveEprById: 'approveEprById',
            cancelEprById: 'cancelEprById',
            convertEprToContractById: 'convertEprToContractById',
            copyContractToEpr: 'copyContractToEpr',
            copyPreviousEprToEpr: 'copyPreviousEprToEpr',
            createEpr: 'createEpr',
            getEprToUpdateById: 'getEprToUpdateById',
            rejectEprById: 'rejectEprById',
            resetAllActiveEprs: 'resetAllActiveEprs',
            resetCurrentEpr: 'resetCurrentEpr',
            resetUpdateEpr: 'resetUpdateEpr',
            submitEprById: 'submitEprById',
            updateEpr: 'updateEpr',
        }),
        ...mapActions('epritem',{
            copyContractFscToEprItems: 'copyContractFscToEprItems',
            copyContractItemsToEprItems: 'copyContractItemsToEprItems',
            copyPreviousEprItemsToEprItems: 'copyPreviousEprItemsToEprItems',
            copyPreviousEprFscToEprItems: 'copyPreviousEprFscToEprItems',
            getItemsByEpr: 'getItemsByEpr',
            resetEprItems: 'resetEprItems',
        }),
        ...mapActions('incoterm', {
            getContractIncoTerms: 'getContractIncoTerms'
        }),
        ...mapActions('paymentterm', {
            getAllPaymentTerms: 'getAllPaymentTerms'
        }),
        ...mapActions('salescolleague', {
            getAllSalesColleagues: 'getAllSalesColleagues',
        }),
        ...mapActions('supplier', {
            resetAllSuppliers: 'resetAllSuppliers',
            getAllSuppliers: 'getAllSuppliers'
        }),
        ...mapActions('shippingport', {
            getAllShippingPorts: 'getAllShippingPorts',
        }),
        addEpr() {
            this.validateEprItems = true
            let _this = this
            setTimeout(function(){
                if(_this.validEprItems) {
                    if(_this.$refs.eprForm.validate()) {
                        _this.loading.add = true
                        _this.createEpr()
                            .then((response) => {
                                if (response.status == 'success') {
                                    _this.$toast.success(_this.$t('message.successes.eprAdded'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'check_circle_outline'
                                        }
                                    )
                                    _this.loading.add = false
                                    _this.newExportPurchaseRequestUnsaved = false
                                    _this.newExportPurchaseRequestSaved = true
                                    _this.resetCurrentEpr().then(() => {
                                        _this.resetAllActiveEprs().then(() => {
                                            router.push({
                                                name: 'update_epr',
                                                params: {eprId: response.epr_id}
                                            }).catch(err => {
                                            })
                                        })
                                    })
                                } else {
                                    if(isObject(response.message)){
                                        //extract validation errors
                                        const errors = Object.values(response.message)
                                        errors.forEach(errorObj => {
                                            if (Array.isArray(errorObj)) {
                                                errorObj.forEach(error => {
                                                    _this.$toast.error(error,
                                                        {
                                                            classes: ['icon-float-left'],
                                                            icon: 'error_outline'
                                                        }
                                                    )
                                                })
                                            } else {
                                                _this.$toast.error(_this.$t('message.errors.eprNotAdded'),
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                            }
                                        })
                                    } else {
                                        _this.$toast.error(_this.$t('message.errors.eprNotAdded'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                    _this.loading.add = false
                                }
                            })
                            .catch(() => {
                                _this.$toast.error(_this.$t('message.errors.eprNotAdded'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                _this.loading.add = false
                            })
                            .finally(() => {
                                _this.loading.add = false
                            })
                    } else {
                        _this.$toast.error(_this.$t('message.errors.eprNotAdded'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        _this.loading.add = false
                        scrollToFirstFormValidationError()
                    }
                } else {
                    _this.$toast.error(_this.$t('message.errors.eprNotAdded'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    _this.loading.add = false
                    scrollToFirstFormValidationError()
                }
            },1000)
        },
        addUploadedFile (fileObject){
            if(this.Epr__documents.length == 0){
                this.Epr__documents.push({AssetDocument: fileObject.AssetDocument})
            } else {
                //add/replace file in existing list
                let doctype_id = fileObject.AssetDocument.doctype_id
                let documents = []
                this.Epr__documents.forEach((item, index)=>{
                    if(doctype_id != item.AssetDocument.doctype_id){
                        documents.push(item)
                    }
                })
                let newfile = {
                    AssetDocument: fileObject.AssetDocument
                }
                documents.push(newfile)
                this.Epr__documents = []
                this.Epr__documents = documents
            }
        },
        async approveEpr (val){
            if (await this.$root.$confirm(this.$t('message.approveEpr'), this.$t('message.confirmations.continueEprApproval'), {color: 'success'})) {
                this.loading.approve = true
                this.approveEprById(val)
                    .then(status => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.eprApproved'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.approve = false
                        } else {
                            this.$toast.error(this.$t('message.errors.eprNotApproved'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.eprNotApproved'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
                    .finally(() => {
                        this.loading.approve = false
                    })
            }
        },
        buyerChanged () {
            if(this.newContractBuyer != null){
                let buyer = this.allBuyers.find(b => b.Customer.id == this.newContractBuyer)
                if( buyer.Customer.paymentstatement_id != null){
                    this.newContractBuyerTerm = buyer.Customer.paymentstatement_id
                }
                if(buyer.Customer.salescontact_id != null){
                    this.newContractSalesColleague = buyer.Customer.salescontact_id
                }
                if(buyer.Customer.id != null){
                    //try to get POD of latest contract for this buyer
                    api
                        .get('/customers/' + this.newContractBuyer + '/shipping-ports')
                        .then((response) => {
                            if(response.data.data.length > 0){
                                this.newContractPod = response.data.data[0]['port_id']
                            }
                        })
                        .catch()
                }
            }
        },
        async cancelEpr (val) {
            if(await this.$root.$confirm(this.$t('message.cancelEpr'), this.$t('message.confirmations.continueEprCancelAction'), {color: 'orange'})) {
                this.cancelEprById(val)
                    .then(response => {
                        if (response.status == 'success') {
                            this.$toast.success(this.$t('message.successes.eprDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            router.push({name: 'epr_listing'}).catch(err => {
                            })
                            this.loading.cancel = false
                        } else {
                            this.$toast.error(this.$t('message.errors.eprNotDeleted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.eprNotDeleted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
                    .finally(() => {
                        this.loading.cancel = false
                    })
            }
        },
        contractSpecies (ids) {
            let array = []
            if(ids != null) {
                ids.forEach((id) => {
                    let species = this.findSpeciesById(id)
                    if (species) {
                        array.push(species.Species.title)
                    }
                })
            }
            return array
        },
        convertEprToContract (val) {
            if (this.$refs.convertToContractForm.validate()) {
                this.loading.convert = true
                let payload = {
                    prefix: this.statePrefix,
                    epr_id: val,
                    contract_number: this.newContractNo,
                    contract_buyer: this.newContractBuyer,
                    contract_buyer_term: this.newContractBuyerTerm,
                    contract_pod: this.newContractPod,
                    contract_sales_colleague: this.newContractSalesColleague,
                    setContract: true
                }
                this.convertEprToContractById(payload)
                    .then(status => {
                        if (status == 'done') {
                            this.loading.convert = false
                            this.newContractNo = null
                            this.newContractBuyer = null
                            this.newContractBuyerTerm = null
                            this.newContractPod = null
                            this.newContractSalesColleague = null
                            this.convertDialog = false
                            this.$toast.success(this.$t('message.successes.eprConverted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.pageKey = Math.floor(Math.random() * 100)
                        } else {
                            this.loading.convert = false
                            this.$toast.error(this.$t('message.errors.eprNotConverted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.loading.convert = false
                        this.$toast.error(this.$t('message.errors.eprNotConverted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
            } else {
                this.$toast.error(this.$t('message.errors.eprNotConverted'),
                    {
                        classes: ['icon-float-left'],
                        icon: 'error_outline'
                    }
                )
            }
        },
        copyContract() {
            this.itemsCopied = false
            let payload = {
                prefix: this.statePrefix,
                contract: this.selectedContract
            }
            this.loading.copy = true
            this.copyContractToEpr(payload)
                .then(() => {
                    let contractId = this.selectedContract[0]['Contract']['id']
                    if(contractId){
                        let payloadEprItems = {
                            prefix: this.statePrefix,
                            contractId: contractId
                        }
                        this.copyContractItemsToEprItems(payloadEprItems)
                            .then(() => {
                                let fsc = this.selectedContract[0]['Contract']['fsc']
                                let payloadFsc = {
                                    prefix: this.statePrefix,
                                    fsc: fsc
                                }
                                this.itemsCopied = true
                                this.copyContractFscToEprItems(payloadFsc)
                                this.selectedContract = []
                                this.loading.copy = false
                                this.selectContractDialog = false
                            })
                            .catch(() => {
                                this.loading.copyEpr = false
                                this.selectEprDialog = false
                            })
                    }
                })
                .catch(() => {
                    this.loading.copy = false
                })
        },
        copyEpr() {
            this.itemsCopied = false
            let payload = {
                prefix: this.statePrefix,
                epr: this.selectedEpr
            }
            this.loading.copyEpr = true
            this.copyPreviousEprToEpr(payload)
                .then(() => {
                    let eprId = this.selectedEpr[0]['Epr']['id']
                    if(eprId){
                        let payloadEprItems = {
                            prefix: this.statePrefix,
                            eprId: eprId
                        }
                        this.copyPreviousEprItemsToEprItems(payloadEprItems)
                            .then(() => {
                                let fsc = this.selectedEpr[0]['Contract']['fsc']
                                let payloadFsc = {
                                    prefix: this.statePrefix,
                                    fsc: fsc
                                }
                                this.itemsCopied = true
                                this.copyPreviousEprFscToEprItems(payloadFsc)
                                this.selectedEpr = []
                                this.loading.copyEpr = false
                                this.selectEprDialog = false
                            })
                            .catch(() => {
                                this.loading.copyEpr = false
                                this.selectEprDialog = false
                            })
                    }
                })
                .catch(() => {
                    this.loading.copyEpr = false
                })
        },
        currency(currency_id) {
            return this.allCurrencies.find((currency) => currency.Currency.id == currency_id)?.Currency?.code
        },
        dialogClosed () {
            this.dialogs.error = false;
            this.dialogs.error_message = ''
        },
        filterEprResults () {
            this.loading.filterEprResults = true
            this.eprTableOptions.page = 1
            let _this = this
            setTimeout(function(){
                _this.loading.filterEprResults = false
            },700)
        },
        filterResults () {
            this.loading.filterResults = true
            this.tableOptions.page = 1
            let _this = this
            setTimeout(function(){
                _this.loading.filterResults = false
            },700)
        },
        formatDate,
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        getContainerSize (containersize_id) {
            let container = this.containerSizes.find(containerSize => containerSize.Containersize.id == containersize_id)
            if( container ) {
                return container?.Containersize.title
            } else {
                return null
            }
        },
        getEprs() {
            return new Promise((resolve, reject) => {
                api
                    .get("/eprs")
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getExportContracts(){
            return new Promise((resolve, reject) => {
                api
                    .get("/contracts", {
                        params: {
                            conditions: [
                                {
                                    field: 'contracts.is_export',
                                    value: 1
                                },
                                {
                                    field: 'contracts.salestype_id',
                                    value: [1]
                                },
                                {
                                    field: 'contracts.salestatus_id',
                                    value: [1,2]
                                }
                            ],
                            order: [
                                {
                                    field: 'contracts.contractdate',
                                    direction: 'DESC'
                                },
                            ]
                        }
                    })
                    .then(response => {
                        resolve(response.data.data)
                    })
                    .catch(error => {
                        reject(error);
                    });
            })
        },
        getStatus (val) {
            let status = this.eprStatuses.find(s => s.Eprstatus.id == this.Epr__status_id)
            return status?.Eprstatus.title
        },
        incotermChanged (val) {
            if(val == 1) this.Epr__incotermport = 1
            if(val == 2) this.Epr__incotermport = 0
        },
        loadEprById (val) {
            this.loader = true
            this.getEprToUpdateById(val)
                .then(() => {
                    this.$title = this.$t('message.titles.epr') + ": " + this.Epr__title
                    let payload = {
                        epr_id: val,
                        prefix: this.statePrefix
                    }
                    this.getItemsByEpr(payload)
                        .then(() => {
                            this.loader = false;
                            this.updatedExportPurchaseRequestUnsaved = false
                            this.itemsLoaded = true
                        })
                        .catch(()=>{
                            this.loader = false
                            this.updatedExportPurchaseRequestUnsaved = false
                            this.itemsLoaded = true
                        })
                })
                .catch(()=>{
                    this.updatedExportPurchaseRequestUnsaved = false
                    this.loader = false;
                    router
                        .push({name: 'not_found_main', params: {resource: 'export purchase request'}})
                        .catch(() => {
                            console.log('error')
                        })
                })
        },
        eprList() {
            let tab = window.open('/v1/eprs/overview','_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        newEpr() {
            let tab = window.open('/v1/eprs/add/' + this.Epr__salescontact_id + '/' + this.Epr__customer_id,'_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        },
        openSelectContractDialog() {
            this.selectContractDialog = true
        },
        openSelectEprDialog() {
            this.selectEprDialog = true
        },
        async rejectEpr(val) {
            if (await this.$root.$confirm(this.$t('message.rejectEpr'), this.$t('message.confirmations.continueEprReject'), {color: 'error'})) {
                this.loading.reject = true
                this.rejectEprById(val)
                    .then(status => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.eprRejected'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.reject = false
                        } else {
                            this.$toast.error(this.$t('message.errors.eprNotRejected'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.wprNotRejected'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
                    .finally(() => {
                        this.loading.reject = false
                    })
            }
        },
        salesColleagueChanged() {
            this.filteredBuyers = []
            if(this.newContractSalesColleague != null) {
                this.filteredBuyers = this.allBuyers.filter(b => b.Customer.salescontact_id == this.newContractSalesColleague && ![1,2,3,4,5,12,68,69,71].includes(b.Company.country_id))
            }
        },
        saveEpr() {
            this.validateEprItems = true
            let _this = this
            setTimeout(function(){
                if(_this.validEprItems) {
                    if (_this.$refs.eprForm.validate()) {
                        _this.loading.save = true
                        _this.updateEpr()
                            .then((response) => {
                                if (response.status == 'success') {
                                    _this.$toast.success(_this.$t('message.successes.eprUpdated'),
                                        {
                                            classes: ['icon-float-left'],
                                            icon: 'check_circle_outline'
                                        }
                                    )

                                    if(_this.previewDialog) _this.previewDialog = false

                                    let payload = {
                                        epr_id: _this.Epr__id,
                                        prefix: _this.statePrefix
                                    }
                                    _this.getItemsByEpr(payload)
                                        .then(() => {
                                            _this.loading.save = false
                                            _this.updatedContractRequestUnsaved = false
                                            _this.itemListKey = Math.floor(Math.random() * 100)
                                            _this.itemsLoaded = true
                                        })
                                        .catch(() => {
                                            _this.loading.save = false
                                            _this.updatedContractRequestUnsaved = false
                                        })
                                        .then(() => {
                                            _this.loading.save = false
                                            _this.updatedContractRequestUnsaved = false
                                            _this.itemListKey = Math.floor(Math.random() * 100)
                                            _this.itemsLoaded = true
                                        })
                                } else {
                                    if(isObject(response.message)){
                                        //extract validation errors
                                        const errors = Object.values(response.message)
                                        errors.forEach(errorObj => {
                                            if (Array.isArray(errorObj)) {
                                                errorObj.forEach(error => {
                                                    _this.$toast.error(error,
                                                        {
                                                            classes: ['icon-float-left'],
                                                            icon: 'error_outline'
                                                        }
                                                    )
                                                })
                                            } else {
                                                _this.$toast.error(_this.$t('message.errors.purchaseOrderNotAdded'),
                                                    {
                                                        classes: ['icon-float-left'],
                                                        icon: 'error_outline'
                                                    }
                                                )
                                            }
                                        })
                                    } else {
                                        _this.$toast.error(_this.$t('message.errors.eprNotUpdated'),
                                            {
                                                classes: ['icon-float-left'],
                                                icon: 'error_outline'
                                            }
                                        )
                                    }
                                    _this.loading.save = false
                                }
                            })
                            .catch(() => {
                                _this.$toast.error(_this.$t('message.errors.eprNotUpdated'),
                                    {
                                        classes: ['icon-float-left'],
                                        icon: 'error_outline'
                                    }
                                )
                                _this.loading.save = false
                            })
                    } else {
                        _this.$toast.error(_this.$t('message.errors.eprNotUpdated'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        _this.loading.save = false
                        scrollToFirstFormValidationError()
                    }
                } else {
                    _this.$toast.error(_this.$t('message.errors.eprNotUpdated'),
                        {
                            classes: ['icon-float-left'],
                            icon: 'error_outline'
                        }
                    )
                    _this.loading.save = false
                    scrollToFirstFormValidationError()
                }
            }, 1000)
        },
        setItemsValidity(val){
            this.validEprItems = val
            this.validateEprItems = false
        },
        async submitEprForApproval (val) {
            if (await this.$root.$confirm(this.$t('message.submitEpr'), this.$t('message.confirmations.continueEprSubmit'), {color: 'orange'})) {
                this.loading.submit = true
                this.submitEprById(val)
                    .then(status => {
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.eprSubmitted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.submit = false
                        } else {
                            this.$toast.error(this.$t('message.errors.eprNotSubmitted'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.eprNotSubmitted'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                    })
                    .finally(() => {
                        this.loading.submit = false
                        this.$vuetify.goTo( this.$refs.actionsRow )
                    })
            }
        },
        viewPdf(type = null) {
            let document = encodeURIComponent(this.Epr__title)
            let tab = window.open(
                process.env.VUE_APP_PDF_SERVER
                + '/api/render/'
                + document
                + '?url='
                + process.env.VUE_APP_BASE_URL
                + (type == 'purchaseOrder' ? '/v1/eprs/po/print/' : '/v1/eprs/print/')
                + this.eprId
                + '/'
                + uuidv4()
                + '&waitFor=h1&pdf.margin.top=15mm&pdf.margin.bottom=5mm&pdf.margin.left=20mm&pdf.margin.right=8mm&goto.timeout=0'
                , "_blank");
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    },
    watch: {
        convertDialog (val) {
            if( !val ) this.newContractNo = null
            if( !val ) this.newContractBuyer = null
            if( !val ) this.newContractBuyerTerm = null
            if( !val ) this.newContractPod = null
            if( !val ) this.newContractSalesColleague = null
        }
    },
    beforeMount () {
        this.resetCurrentEpr()
        this.resetUpdateEpr()
        this.resetEprItems(this.statePrefix)
    },
    created () {
        if(this.allBuyers.length == 0) this.getAllActiveBuyers()
        if(this.allCountries.length == 0) this.getAllCountries()
        if(this.allBuyerPaymentTerms.length == 0) this.getAllPaymentTerms()
        if(this.allSalesColleagues.length == 0) this.getAllSalesColleagues()
        if(this.allSuppliers.length == 0) this.getAllSuppliers()
        if(this.allCurrencies.length == 0) this.getAllCurrencies()
        if(this.contractIncoTerms.length == 0) this.getContractIncoTerms()

        if(this.$route.matched.some(({name}) => name === 'update_epr')){
            this.loadEprById(this.eprId)
            this.Epr__documents = []
            this.getExportPurchaseRequestDocumentsById(this.eprId)
                .then((documents)=>{
                    this.Epr__documents = documents
                    this.showRelatedFiles = true
                })
                .catch(() => {
                    this.showRelatedFiles = true
                })
        } else if(this.$route.matched.some(({name}) => name === 'add_epr')) {
            this.resetCurrentEpr();
            this.resetEprItems(this.statePrefix);
            this.getExportContracts()
                .then(data => {
                    this.filteredContracts = data
                    this.loading.contracts = false
                })
                .catch(() => {
                    this.loading.contracts = false
                })
            this.getEprs()
                .then((data) => {
                    this.filteredEprs = data
                    this.loading.eprs = false
                })
                .catch(() => {
                    this.loading.eprs = false
                })
        }
    },
    mounted () {
        this.$store.watch(
            function(state) {
                return state.appic.epr.update.Epr
            },
            () => {
                this.updatedContractRequestUnsaved = true
            },
            { deep: true }
        )
        this.$store.watch(
            function(state) {
                return state.appic.epr.current.Epr
            },
            () => {
                this.newContractRequestUnsaved = true
            },
            { deep: true }
        )
        if(this.$route.matched.some(({name}) => name === 'add_epr')){
            this.Epr__eprdate = new Date().toISOString().substr(0,10)
        }
        this.filteredSalesColleagues = this.allSalesColleagues.filter(sc => [14,49,51].includes(sc.Salesperson.id))
        this.filteredBuyers = this.allBuyers.filter(b => {
            return [14,49,51].includes(b.Customer.salescontact_id) && ![1,2,3,4,5,12,68,69,71].includes(b.Company.country_id)
        })
    }
}
</script>

<style scoped>
.force-text-left >>> .v-text-field__slot input {
    text-align: left !important;
}

.v-dialog {
    min-width: 200px !important;
}

.wrap-text {
    white-space: normal;
}

.font-xs {
    font-size: small;
}
</style>